body .gist {
  max-width: 100vw;
}

body .gist .gist-file {
  border-radius: 0;
  border-style: none;
  margin-bottom: 0;
}

body .gist .gist-data {
  background-color: #e5e5e5;
  border-bottom: none;
  border-radius: 0;
}

body .gist .blob-wrapper {
  border-radius: 0;
}

body .gist .highlight {
  background-color: transparent;
  font-size: 14px;
}

body .gist .highlight td {
  line-height: 1;
  padding: 5px 15px !important;
}

body .gist .blob-num {
  background-color: #333333;
  color: #b2b2b2;
  pointer-events: none;
}

body .gist .gist-meta {
  background-color: #333333;
}

@media only screen and (max-width: 999px) {
  body .gist .gist-data {
    display: none;
  }
}
