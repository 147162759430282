.site-header {
  grid-area: header;
}

.site-sidebar {
  grid-area: sidebar;
  max-width: 350px;
}

.site-content {
  grid-area: content;
  max-width: 800px;
}

.site-footer {
  grid-area: footer;
}

.footer-area1 {
  grid-area: footer1;
}

.footer-area2 {
  grid-area: footer2;
}

.footer-area3 {
  grid-area: footer3;
}

.footer-area4 {
  grid-area: footer4;
}

.site-wrapper {
  display: grid;
  grid-template-areas:
    "header"
    "sidebar"
    "content"
    "footer";
  justify-content: center;
}

.footer-wrapper {
  display: grid;
  grid-template-areas:
    "footer1"
    "footer2"
    "footer3"
    "footer4";
  justify-content: flex-start;
}

@media (min-width: 800px) {
  .site-wrapper {
    grid-template-areas:
      "header"
      "sidebar"
      "content"
      "footer";
    grid-template-columns: 800px;
    grid-template-rows: auto;
  }
  .footer-wrapper {
    display: grid;
    grid-template-areas:
      "footer1"
      "footer2"
      "footer3"
      "footer4";
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
}

@media (min-width: 800px) {
  .site-wrapper {
    grid-template-areas:
      "header  header  header"
      "content content sidebar"
      "footer  footer  footer";
    grid-template-columns: auto;
    grid-template-rows: auto;
   }
  .footer-wrapper {
    display: grid;
    grid-template-areas:
      "footer1 footer2 footer3"
      "footer4 footer4 footer4";
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
}

@media screen and (max-width: 799px) {
  .site-nav a:not(:first-child) {
    display: none;
  }
  .site-nav a.navbar-hamburger {
    display: block;
    float: right;
  }
  .dropdown {
    display: none;
  }
  .dropdown-list {
    width: 100vw;
  }
  .site-sidebar {
    margin-bottom: 0;
    max-width: 100%;
  }
  .sidebar-content {
    margin-bottom: 0 !important;
  }
  .site-content {
    margin-right: 30px;
    margin-top: 30px;
    max-width: 100%;
  }
  .site-footer {
    left: 0;
  }
  .footer-paragraph {
    width: 100%;
  }
}

@media screen and (max-width: 799px) {
  .site-nav.responsive {
    width: 100vw;
  }
  .site-nav.responsive a.navbar-hamburger {
    position: absolute;
    right: 0;
    top: 0;
  }
  .site-nav.responsive a {
    display: block;
    float: none;
    text-align: left;
  }
  .dropdown.responsive {
    display: block;
    width: 100vw;
  }
}
