@charset "utf-8";

$background-color: #4c4c4c !default;
$base-font-family: "Palatino Linotype", "Book Antiqua", Palatino, Georgia, serif !default;
$base-font-size: 17px !default;
$base-line-height: 1.75 !default;
$base-text-color: #ffffff !default;

.site-wrapper {
  width: 100vw;
}

.site {
  background-color: $background-color;
  background-size: cover;
  color: $base-text-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  margin: 10px 0 10px 0;
  min-height: 100vh;
  padding: 0;
  position: absolute;
}

.site-content {
  background-color: #696969;
  border-radius: 5px;
  margin: 76px 0 31px 30px;
  padding: 20px 40px 40px 40px;
}

.page-header {
  border-bottom-color: #4c4c4c;
  border-bottom-style: solid;
  margin: 0 0 50px 0;
  padding: 0 0 12px 0;
}

@import
  "components/elementstyle",
  "components/footer",
  "components/form",
  "components/gist",
  "components/layout",
  "components/navbar",
  "components/sidebar",
  "components/table"
;
