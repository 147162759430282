h1 {
  font-size: 2em;
  text-shadow: 2px 2px #000000;
}

h2 {
  font-size: 1.75em;
  text-shadow: 2px 2px #000000;
}

h3 {
  font-size: 1.5em;
  text-shadow: 2px 2px #000000;
}

h4 {
  font-size: 1.25em;
  text-shadow: 2px 2px #000000;
}

h5 {
  font-size: 1em;
  text-shadow: 2px 2px #000000;
}

h6 {
  font-size: 0.75em;
}

p {
  padding: 10px 0 10px 0;
}

blockquote {
  background-color: #4c4c4c;
  border-left-color: #000000;
  border-left-style: solid;
  border-left-width: 6px;
  padding: 20px;
}

body ol ol {
  padding-left: 20px;
}

body ol ul {
  padding-left: 20px;
}

body ul {
  list-style: circle;
}

body ul ul {
  padding-left: 20px;
}

body ul ol {
  padding-left: 20px;
}

figure {
  background-color: #4c4c4c;
  margin: 0 0 30px 0;
  padding: 10px 20px 0 20px;
}

figcaption {
  margin: 0 0 10px 0;
}

a:link, a:visited {
  color: #ffffff;
}

a:hover, a:visited:hover {
  color: #000000;
}

img {
  max-width: 100%;
  padding: 10px 0 10px 0;
}

.left {
  float: left;
  margin-right: 20px;
}

.right {
  float: right;
  margin-left: 20px;
}

.center {
  display: block;
  margin-left:auto;
  margin-right:auto;
  padding-bottom: 1px;
  text-align: center;
}

.small {
  width: 200px;
}

@media screen and (max-width: 500px) {
  .small {
    display: block;
    float: none;
    margin-left:auto;
    margin-right:auto;
  }
}

.medium {
  width: 350px;
}

@media screen and (max-width: 950px) {
  .medium {
    display: block;
    float: none;
    margin-left:auto;
    margin-right:auto;
  }
}

.large {
  width: 500px;
}

@media screen and (max-width: 1110px) {
  .large {
    display: block;
    float: none;
    margin-left:auto;
    margin-right:auto;
  }
}

.full {
  display: block;
  float: none;
  margin-left:auto;
  margin-right:auto;
  width: 100%;
}

code {
  background-color: #4c4c4c;
  border-radius: 2px;
  font-family: monospace;
  margin: 0 2px 0 2px;
  padding: 2px 5px 2px 5px;
}
