.sidebar-content {
  background-color: #696969;
  border-radius: 5px;
  line-height: 0;
  margin: 30px 30px 31px 30px;
  padding: 20px 30px 0 30px;
}

.site-title {
  background-color: #696969;
  border-radius: 5px;
  margin: 76px 30px 0 30px;
  padding: 1px 30px 0 30px;
}

.sidebar-area {
  margin: 0;
  padding: 0 10px 30px 0;
}

.sidebar-list {
  list-style-type: circle;
  margin: 0 0 0 20px;
  padding: 0 0 0 10px;
}

.sidebar-header {
  line-height: 30px;
  margin: 0;
}
