form {
  background-color: #4c4c4c;
  padding: 20px;
}

fieldset {
  border-color: #000000;
  margin: 0 0 10px 0;
}

textarea {
  font-size: 1em;
  height: 200px;
  width: 100%
}

input[type=text], input[type=email] {
  font-size: 1em;
}

input[type=checkbox] {
  margin-left: 10px;
}

input[type=submit] {
  background-color: #dddddd;
  border: none;
  color: #000000;
  cursor: pointer;
  margin: 10px 0 0 0;
  padding: 16px 32px;
  text-decoration: none;
  width: 100%
}
