footer {
  background-color: #333333;
  bottom: -1;
  left: 0;
  position: absolute;
  width: 100vw;
}

.footer-content {
  color: #bababa;
}

.footer-area {
  margin: 35px auto 0 40px;
}

.footer-area1 {
  margin-right: 40px
}

.footer-area2 {
  margin-right: 40px
}

.footer-area3 {
  margin-right: 40px
}

.footer-area4 {
  align-content: center;
  font-size: 0.75em;
  margin: 35px 0 10px 0;
  text-align: center;
  width: 100vw;
}

.footer-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-header {
  font-weight: bold;
  margin: 0;
}

.footer-link {
  font-size: 0.75em;
  padding: 0 5px 0 0;
}

a.footer-link:link, a.footer-link:visited {
  color: #bababa;
  text-decoration: none;
}

a.footer-link:hover, a.footer-link:visited:hover {
  color: #ffffff;
}

.connect-img {
  margin: 5px 5px 5px 5px;
  width: 20px;
}

.footer-paragraph {
  font-size: 0.75em;
  padding: 0;
  width: 150px;
}
